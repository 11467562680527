import React, {lazy, Suspense} from 'react';
import { useRoutes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { Spinner } from 'react-bootstrap';

import { PRODUTOS } from "./config/content";
import './styles/style.scss';

const Landing = lazy(()=> import("./screens/Landing"));
const QuemSomos = lazy(()=> import("./screens/QuemSomos"));
const Blog = lazy(()=> import("./screens/Blog"));
const BlogDetalhes = lazy(()=> import("./screens/BlogDetalhes"));
const Produto = lazy(()=> import("./screens/Produto"));
const Page404 = lazy(()=> import("./screens/Page404"));
const PoliticaDePrivacidade = lazy(()=> import("./screens/PoliticaDePrivacidade"));

function AppLoader(){
	return (
		<div style={{backgroundColor: "rgb(248, 249, 250)", position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 2000, color: '#374B6E', fontWeight: 'bold'}}>
			Apps4Business<br/><br/>
			<Spinner/>
		</div>
	)
}

export default function App() {
	
	const element = useRoutes([
	  	{ path: "/", element: <Landing />},
	  	{ path: "/quemsomos", element: <QuemSomos />},
	  	{ path: "/blog", element: <Blog />},
	  	{ path: "/blogDetalhes/:id", element: <BlogDetalhes />},
	  	{ path: "/meuplano", element: <Produto data={PRODUTOS.meuplano} />},
	  	{ path: "/smarthealth", element: <Produto data={PRODUTOS.smarthealth} />},
	  	{ path: "/hospitalplus", element: <Produto data={PRODUTOS.hospitalplus} />},
	  	{ path: "/medicoplus", element: <Produto data={PRODUTOS.medicoplus} />},
	  	{ path: "/movvy", element: <Produto data={PRODUTOS.movvy} />},
	  	{ path: "/4meeting", element: <Produto data={PRODUTOS.meeting} />},
	  	{ path: "/customizados", element: <Produto data={PRODUTOS.customizados} />},
	  	{ path: "/politica-de-privacidade", element: <PoliticaDePrivacidade />},
	  	{ path: "*", element: <Page404 />},
	]);
  
	const location = useLocation();
  
	if (!element) return null;
  
	return (
		<Suspense fallback={<AppLoader/>}>
			<AnimatePresence mode="wait" initial={false}>
				{React.cloneElement(element, { key: location.pathname })}
			</AnimatePresence>
		</Suspense>
	);
}